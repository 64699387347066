import React, { createContext, useState } from "react";

export type NavbarItem = {
  label: string;
  href: string;
};

export const defaultNavbarItems: NavbarItem[] = [
  { label: "Home", href: "/development" },
  { label: "Projects", href: "/projects" },
  { label: "Contact", href: "/contacts" },
  { label: "About", href: "/about" },
  { label: "Blog", href: "/blog" },
];

export type ApplicationContextType = {
  navbar: {
    isSticky: boolean;
    items: NavbarItem[];
    updateNavBar: (items: NavbarItem[]) => void;
    setNavbarSticky: (isSticky: boolean) => void;
  };
  footer: {
    description: string;
    items: {
      groupName: string;
      items: NavbarItem[];
    }[];
  };
};

export const ApplicationContext = createContext<ApplicationContextType | null>(
  null
);

export const ApplicationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [navbarItems, setNavbarItems] =
    useState<NavbarItem[]>(defaultNavbarItems);
  const [isSticky, setIsSticky] = useState(true);

  const [footerItems, setFooterItems] = useState<
    {
      groupName: string;
      items: NavbarItem[];
    }[]
  >([
    {
      groupName: "Company",
      items: [
        { label: "Alex Leko", href: "/about" },
        { label: "Careers", href: "/contacts" },
      ],
    },
    {
      groupName: "Services",
      items: [
        { label: "Digital Marketing", href: "/marketing" },
        {
          label: "Development",
          href: "/development",
        },
        {
          label: "Music / Podcast",
          href: "/music",
        },
      ],
    },
    {
      groupName: "Resources",
      items: [
        { label: "Blog", href: "/blog" },
        {
          label: "Projects / Portfolio",
          href: "/projects",
        },
        {
          label: "Privacy Policy",
          href: "/privacy-policy",
        },
        {
          label: "FAQ",
          href: "/contacts",
        },
      ],
    },
    {
      groupName: "Contacts",
      items: [
        { label: "Lisbon, Portugal", href: "#" },
        {
          label: "+351 275 753 250",
          href: "tel:+351275753250",
        },
        {
          label: "contacts@ldw.solutions",
          href: "mailto:contacts@ldw.solutions",
        },
      ],
    },
  ]);

  const context = {
    navbar: {
      isSticky,
      items: navbarItems,
      updateNavBar: (items: NavbarItem[]) => {
        setNavbarItems(items);
      },
      setNavbarSticky: (isSticky: boolean) => {
        setIsSticky(isSticky);
      },
    },
    footer: {
      description:
        "We help businesses thrive online with strategic marketing, custom web solutions, and engaging audio content.",
      items: footerItems,
    },
  };

  return (
    <ApplicationContext.Provider value={context}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplication = () => {
  const context = React.useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error("useNavbar must be used within a NavbarProvider");
  }
  return context as ApplicationContextType;
};
