// @ts-ignore
import React from "react";
// custom typefaces
import "@fontsource-variable/montserrat";
import "@fontsource/merriweather";
// TailwindCSS
import "./src/styles/global.css";
// Highlighting for code blocks
import { GatsbyBrowser } from "gatsby";
import "prismjs/themes/prism.css";
import Layout from "./src/components/Layout";
import { ApplicationProvider } from "./src/context/application.context";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: { location },
}) => {
  return (
    <>
      <ApplicationProvider>
        <Layout title="" location={location}>
          {element}
        </Layout>
      </ApplicationProvider>
    </>
  );
};
