import React from "react";

const PreLoader: React.FC = () => {
  const preLoaderRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    // activateMenu()
    if (!preLoaderRef.current) return;
    setTimeout(() => {
      preLoaderRef.current!.style.visibility = "hidden";
      preLoaderRef.current!.style.opacity = "0";
    }, 350);
  }, []);

  return (
    <div id="preloader" ref={preLoaderRef}>
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
export default PreLoader;
