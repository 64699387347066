import React, { useEffect } from "react";
import { changeTheme, getCookieByName } from "@/lib/utils/utils";

const Switcher: React.FC = () => {
  useEffect(() => {
    try {
      const chk = document.getElementById("chk");
      chk?.addEventListener("change", changeTheme);
      const theme = getCookieByName("theme");
      if (!theme) return;
      if (theme === "dark") {
        document.getElementsByTagName("html")[0].className = theme;
      }
    } catch (err) {
      console.error("Error on apply theme", err);
    }
  }, []);

  return (
    <div className="fixed switcher top-1/4 -right-1 z-3">
      <span className="relative inline-block rotate-90">
        <input
          type="checkbox"
          className="checkbox opacity-0 absolute"
          id="chk"
        />
        <label
          className="label bg-secondary dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8 text-yellow-500"
          htmlFor="chk"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            className="w-6 h-6"
            fill="none"
          >
            <g fill="none" fillRule="evenodd">
              <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
              <path
                fill="currentColor"
                d="M13.574 3.137a1.01 1.01 0 0 0-1.097 1.409a6 6 0 0 1-7.931 7.931a1.01 1.01 0 0 0-1.409 1.097A9 9 0 0 0 21 12c0-4.434-3.206-8.118-7.426-8.863m1.307 2.481A7.002 7.002 0 0 1 12 19a7 7 0 0 1-6.382-4.12a8 8 0 0 0 9.263-9.263Z"
              ></path>
            </g>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            className="w-6 h-6"
            fill="none"
          >
            <g fill="none" stroke="currentColor" strokeWidth={1.5}>
              <circle cx={12} cy={12} r={6}></circle>
              <path
                strokeLinecap="round"
                d="M12 2v1m0 18v1m10-10h-1M3 12H2m17.07-7.07l-.392.393M5.322 18.678l-.393.393m14.141-.001l-.392-.393M5.322 5.322l-.393-.393"
              ></path>
            </g>
          </svg>
          <span className="ball bg-white dark:bg-secondary rounded-full absolute top-[2px] left-[2px] w-7 h-7"></span>
        </label>
      </span>
    </div>
  );
};
export default Switcher;
