import { toggleMenu } from "@/lib/utils/utils";
import clsx from "clsx";
import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useApplication } from "../context/application.context";

const Navbar = () => {
  const navRef = useRef<HTMLDivElement>(null);
  const {
    navbar: { isSticky, items },
  } = useApplication();

  useEffect(() => {
    function windowScroll() {
      if (!navRef.current) return;
      const navbar = navRef.current as HTMLDivElement;
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }

    function activateScroll(ev: Event) {
      ev.preventDefault();
      windowScroll();
    }

    window.addEventListener("scroll", activateScroll);

    return () => window.removeEventListener("scroll", activateScroll);
  }, []);
  return (
    <nav
      ref={navRef}
      id="topnav"
      className={clsx("defaultscroll is-sticky", {
        "nav-sticky": isSticky,
        "bg-transparent": items.length === 0 && !isSticky,
      })}
    >
      <div className="container">
        <Link className="logo pl-0" to="/">
          <span className="inline-block dark:hidden">
            <img
              src="/images/marca-tipo-rgb.png"
              className="l-dark w-auto h-10"
              alt=""
            />
            <img
              src="/images/marca-tipo-rgb.png"
              className="l-light w-auto h-10"
              alt=""
            />
          </span>
          <img
            src="/images/marca-tipo-rgb.png"
            className="hidden dark:inline-block w-auto h-10"
            alt=""
          />
        </Link>
        <div
          className={clsx("menu-extras", {
            hidden: items.length === 0,
          })}
        >
          <div className="menu-item">
            <button
              className="navbar-toggle"
              id="isToggle"
              onClick={toggleMenu}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
        <div id="navigation">
          <ul className="navigation-menu nav-light @@class">
            {items.map((item, index) => (
              <li key={index}>
                <Link to={item.href} className="sub-menu-item">
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
