exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-development-tsx": () => import("./../../../src/pages/development.tsx" /* webpackChunkName: "component---src-pages-development-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketing-tsx": () => import("./../../../src/pages/marketing.tsx" /* webpackChunkName: "component---src-pages-marketing-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-airport-riogaleao-tsx": () => import("./../../../src/pages/projects/airport-riogaleao.tsx" /* webpackChunkName: "component---src-pages-projects-airport-riogaleao-tsx" */),
  "component---src-pages-projects-clean-watts-tsx": () => import("./../../../src/pages/projects/clean-watts.tsx" /* webpackChunkName: "component---src-pages-projects-clean-watts-tsx" */),
  "component---src-pages-projects-descomplica-tsx": () => import("./../../../src/pages/projects/descomplica.tsx" /* webpackChunkName: "component---src-pages-projects-descomplica-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-ons-tsx": () => import("./../../../src/pages/projects/ons.tsx" /* webpackChunkName: "component---src-pages-projects-ons-tsx" */),
  "component---src-pages-projects-projectbox-tsx": () => import("./../../../src/pages/projects/projectbox.tsx" /* webpackChunkName: "component---src-pages-projects-projectbox-tsx" */),
  "component---src-pages-projects-prudential-of-brazil-tsx": () => import("./../../../src/pages/projects/prudential-of-brazil.tsx" /* webpackChunkName: "component---src-pages-projects-prudential-of-brazil-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

