import ScrollToTopButton from "@/components/ScrollToTopButton";
import { TINY_SLIDER_KEY } from "@/lib/utils/constants";
import { getMenuState, toggleMenu } from "@/lib/utils/utils";
import { Script } from "gatsby";
import * as React from "react";
import { useEffect } from "react";
import { ApplicationProvider } from "../context/application.context";
import Cookies from "./Cookies";
import Footer from "./Footer";
import Navbar from "./Navbar";
import PreLoader from "./PreLoader";
import Switcher from "./Switcher";

declare var __PATH_PREFIX__: string;

export type LayoutProps = {
  location: Location;
  title?: string;
};

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  location,
}) => {
  useEffect(() => {
    const { open } = getMenuState();
    const tobiiDialog = document.querySelector(".tobii");
    if (tobiiDialog && !window.location.pathname.startsWith("/contacts")) {
      tobiiDialog.remove();
    }
    if (!open) return;
    setTimeout(toggleMenu, 100);
  }, [location]);

  return (
    <>
      <PreLoader />
      <Switcher />
      <Navbar />
      {children}
      <Footer />
      <Cookies />
      <ScrollToTopButton />
      <Script
        src="/js/tiny-slider/min/tiny-slider.js"
        onLoad={() => {
          console.log("Tiny Slider loaded");
          localStorage.setItem(TINY_SLIDER_KEY, "loaded");
        }}
      />
    </>
  );
};

export default Layout;
