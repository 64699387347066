import { graphql, useStaticQuery, withPrefix } from "gatsby";
import { Settings } from "@/models/Settings";
import { BaseProperties } from "@/models/BlogProperties";

export const useAuthor: () => Pick<BaseProperties, "author"> & {
  social: {
    [key: string]: string;
  };
} = () => {
  const data = useStaticQuery<{ site: Settings }>(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
            image
          }
          social {
            twitter
            linkedin
            github
            instagram
            facebook
          }
        }
      }
    }
  `);
  const author = data.site.siteMetadata.author;
  const social = data.site.siteMetadata.social;
  return {
    author: {
      summary: author?.summary,
      name: author?.name || "Alex Leko",
      image: author?.image || withPrefix("/images/about/AlexLeko.jpeg"),
    },
    social: {
      github: social.github,
      linkedin: social.linkedin,
      twitter: social.twitter,
    },
  };
};
