import { useAuthor } from "@/hooks/useAuthor";
import { Link } from "gatsby";
import React from "react";
import { useApplication } from "../context/application.context";

const Footer: React.FC = () => {
  const { social } = useAuthor();
  const { footer } = useApplication();
  return (
    <footer className="footer bg-secondary relative text-gray-200 dark:text-gray-200">
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-7 md:col-span-12">
                  <a href="#" className="text-[22px] focus:outline-none">
                    <img
                      src="/images/marca-tipo-rgb.png"
                      className="w-auto h-16"
                      alt=""
                    />
                  </a>
                  <p className="mt-6 text-gray-300">{footer.description}</p>
                  <ul className="list-none mt-6 space-x-2">
                    <li className="inline">
                      <a
                        href={social.linkedin}
                        target="_blank"
                        className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-primary/70 dark:hover:border-primary/70 hover:bg-primary/70 dark:hover:bg-primary/70"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 1024 1024"
                          fill="none"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M847.7 112H176.3c-35.5 0-64.3 28.8-64.3 64.3v671.4c0 35.5 28.8 64.3 64.3 64.3h671.4c35.5 0 64.3-28.8 64.3-64.3V176.3c0-35.5-28.8-64.3-64.3-64.3m0 736q-671.7-.15-671.7-.3q.15-671.7.3-671.7q671.7.15 671.7.3q-.15 671.7-.3 671.7M230.6 411.9h118.7v381.8H230.6zm59.4-52.2c37.9 0 68.8-30.8 68.8-68.8a68.8 68.8 0 1 0-137.6 0c-.1 38 30.7 68.8 68.8 68.8m252.3 245.1c0-49.8 9.5-98 71.2-98c60.8 0 61.7 56.9 61.7 101.2v185.7h118.6V584.3c0-102.8-22.2-181.9-142.3-181.9c-57.7 0-96.4 31.7-112.3 61.7h-1.6v-52.2H423.7v381.8h118.6z"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href={social.facebook}
                        target="_blank"
                        className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-primary/70 dark:hover:border-primary/70 hover:bg-primary/70 dark:hover:bg-primary/70"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                          fill="none"
                        >
                          <path
                            fill="currentColor"
                            d="M14 19h5V5H5v14h7v-5h-2v-2h2v-1.654c0-1.337.14-1.822.4-2.311A2.73 2.73 0 0 1 13.536 6.9c.382-.205.857-.328 1.687-.381q.494-.032 1.278.08v1.9H16c-.917 0-1.296.043-1.522.164a.73.73 0 0 0-.314.314c-.12.226-.164.45-.164 1.368V12h2.5l-.5 2h-2zM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href={social.instagram}
                        target="_blank"
                        className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-primary/70 dark:hover:border-primary/70 hover:bg-primary/70 dark:hover:bg-primary/70"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                          fill="none"
                        >
                          <path
                            fill="currentColor"
                            d="M17.34 5.46a1.2 1.2 0 1 0 1.2 1.2a1.2 1.2 0 0 0-1.2-1.2m4.6 2.42a7.6 7.6 0 0 0-.46-2.43a4.9 4.9 0 0 0-1.16-1.77a4.7 4.7 0 0 0-1.77-1.15a7.3 7.3 0 0 0-2.43-.47C15.06 2 14.72 2 12 2s-3.06 0-4.12.06a7.3 7.3 0 0 0-2.43.47a4.8 4.8 0 0 0-1.77 1.15a4.7 4.7 0 0 0-1.15 1.77a7.3 7.3 0 0 0-.47 2.43C2 8.94 2 9.28 2 12s0 3.06.06 4.12a7.3 7.3 0 0 0 .47 2.43a4.7 4.7 0 0 0 1.15 1.77a4.8 4.8 0 0 0 1.77 1.15a7.3 7.3 0 0 0 2.43.47C8.94 22 9.28 22 12 22s3.06 0 4.12-.06a7.3 7.3 0 0 0 2.43-.47a4.7 4.7 0 0 0 1.77-1.15a4.85 4.85 0 0 0 1.16-1.77a7.6 7.6 0 0 0 .46-2.43c0-1.06.06-1.4.06-4.12s0-3.06-.06-4.12M20.14 16a5.6 5.6 0 0 1-.34 1.86a3.06 3.06 0 0 1-.75 1.15a3.2 3.2 0 0 1-1.15.75a5.6 5.6 0 0 1-1.86.34c-1 .05-1.37.06-4 .06s-3 0-4-.06a5.7 5.7 0 0 1-1.94-.3a3.3 3.3 0 0 1-1.1-.75a3 3 0 0 1-.74-1.15a5.5 5.5 0 0 1-.4-1.9c0-1-.06-1.37-.06-4s0-3 .06-4a5.5 5.5 0 0 1 .35-1.9A3 3 0 0 1 5 5a3.1 3.1 0 0 1 1.1-.8A5.7 5.7 0 0 1 8 3.86c1 0 1.37-.06 4-.06s3 0 4 .06a5.6 5.6 0 0 1 1.86.34a3.06 3.06 0 0 1 1.19.8a3.1 3.1 0 0 1 .75 1.1a5.6 5.6 0 0 1 .34 1.9c.05 1 .06 1.37.06 4s-.01 3-.06 4M12 6.87A5.13 5.13 0 1 0 17.14 12A5.12 5.12 0 0 0 12 6.87m0 8.46A3.33 3.33 0 1 1 15.33 12A3.33 3.33 0 0 1 12 15.33"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href={social.twitter}
                        target="_blank"
                        className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-primary/70 dark:hover:border-primary/70 hover:bg-primary/70 dark:hover:bg-primary/70"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="-2 -2 24 24"
                          className="w-6 h-6"
                          fill="none"
                        >
                          <g fill="currentColor">
                            <path d="M15 6.947c-.368.16-.763.27-1.178.318c.424-.25.748-.646.902-1.117a4.2 4.2 0 0 1-1.304.49A2.06 2.06 0 0 0 11.923 6c-1.133 0-2.051.905-2.051 2.02q0 .237.053.46a5.85 5.85 0 0 1-4.228-2.11a2 2 0 0 0-.278 1.015c0 .7.363 1.32.913 1.681a2.1 2.1 0 0 1-.93-.253v.025a2.03 2.03 0 0 0 1.646 1.98a2.1 2.1 0 0 1-.927.034a2.05 2.05 0 0 0 1.916 1.403a4.16 4.16 0 0 1-2.548.864q-.248 0-.489-.028A5.86 5.86 0 0 0 8.144 14c3.774 0 5.837-3.078 5.837-5.748l-.007-.262A4.1 4.1 0 0 0 15 6.947"></path>
                            <path d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4"></path>
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href={social.github}
                        target="_blank"
                        className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-primary/70 dark:hover:border-primary/70 hover:bg-primary/70 dark:hover:bg-primary/70"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                          fill="none"
                        >
                          <path
                            fill="currentColor"
                            d="M12 2.247a10 10 0 0 0-3.162 19.487c.5.088.687-.212.687-.475c0-.237-.012-1.025-.012-1.862c-2.513.462-3.163-.613-3.363-1.175a3.64 3.64 0 0 0-1.025-1.413c-.35-.187-.85-.65-.013-.662a2 2 0 0 1 1.538 1.025a2.137 2.137 0 0 0 2.912.825a2.1 2.1 0 0 1 .638-1.338c-2.225-.25-4.55-1.112-4.55-4.937a3.9 3.9 0 0 1 1.025-2.688a3.6 3.6 0 0 1 .1-2.65s.837-.262 2.75 1.025a9.43 9.43 0 0 1 5 0c1.912-1.3 2.75-1.025 2.75-1.025a3.6 3.6 0 0 1 .1 2.65a3.87 3.87 0 0 1 1.025 2.688c0 3.837-2.338 4.687-4.562 4.937a2.37 2.37 0 0 1 .674 1.85c0 1.338-.012 2.413-.012 2.75c0 .263.187.575.687.475A10.005 10.005 0 0 0 12 2.247"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li className="inline">
                      <a
                        href={`mailto:${social.email}`}
                        className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-primary/70 dark:hover:border-primary/70 hover:bg-primary/70 dark:hover:bg-primary/70"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                          fill="none"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 7v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7m18 0a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1m18 0l-7.72 6.433a2 2 0 0 1-2.56 0L3 7"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid grid-cols-12 mt-6 py-2 md:py-4 border-t border-primary/60">
                {footer.items.map((item) => (
                  <div
                    className="col-span-6 md:col-span-3 mt-4 md:mt-0"
                    key={item.groupName}
                  >
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      {item.groupName}
                    </h5>
                    <ul className="list-none footer-list mt-6">
                      {item.items.map(({ label, href }) => (
                        <li className="mb-2" key={label}>
                          {href.startsWith("/") ? (
                            <Link
                              to={href}
                              className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              {label}
                            </Link>
                          ) : (
                            <a
                              href={href}
                              target="_blank"
                              className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              {label}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full py-[30px] px-0 border-t border-primary/60">
        <div className="md:text-left text-center">
          <p className="mb-0 leading-5 text-center gap-2">
            &copy; <span className="font-ldw tracking-wider">LDW</span>{" "}
            <span className="font-solutions tracking-widest">solution</span> |{" "}
            <span className="tracking-normal">
              <span className="font-ldw">L</span>eko{" "}
              <span className="font-ldw">D</span>evelopment for{" "}
              <span className="font-ldw">W</span>eb Consulting - All right
              reserved. 2015-{new Date().getFullYear()}
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
